import React, { useState } from "react";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ArticleContentData, FeaturedContentStackArticlesData } from "../../data/ContentStack";
import NewsCard, { NEWS_CARD_TYPE_CLASSES } from "../../components/NewsCard/index";
import Button from "../Commons/Button";
import * as S from "./styles";

interface NewsArchiveProps {
  list: ArticleContentData[];
  title: FeaturedContentStackArticlesData;
}

const initialArticleLimit = 6;

const NewsArchive: React.FC<NewsArchiveProps> = ({ list }) => {
  const [articleLimit, setArticleLimit] = useState(initialArticleLimit);
  const { t } = useTranslation('news');
  const showLoadMore = articleLimit < list.length;

  const loadMoreArticles = () => {
    setArticleLimit(articleLimit + initialArticleLimit);
  };

  return (
    <Container>
      <Row justify="center">
        <Col xs={2} sm={2} md={6} lg={12} xl={12}>
          <S.ArchiveTitle className="animation-opacity">{t("latest")}</S.ArchiveTitle>
          {list
            .filter((_, index) => index < articleLimit)
            .map((article, index) => (
              <div key={`news archive ${index} ${article.title}`}>
                <S.Wrapper className="animation-opacity">
                  <NewsCard
                    newsCardType={NEWS_CARD_TYPE_CLASSES.newsArchive}
                    {...article}
                    index={index}
                    cardType="large"
                  />
                </S.Wrapper>
              </div>
            ))}
          <S.NewsFooter>
            <S.FooterWrapper>
              {showLoadMore && (
                <S.ButtonWrapper>
                  <Button size="large" type={"quinary"} onClick={() => loadMoreArticles()}>
                    <p>{t('load')}</p>
                  </Button>
                </S.ButtonWrapper>
              )}
            </S.FooterWrapper>
          </S.NewsFooter>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsArchive;
