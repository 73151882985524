import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { AllContentStackArticlesData, FeaturedContentStackArticlesData } from "../data/ContentStack";
import NewsArchive from "../components/NewsArchive";
import Seo, { SeoData } from "../components/Seo/Seo";
import NewsHero from "../components/NewsHero";
import { PageContext } from "gatsby-plugin-react-i18next/dist/types";
interface NewsPageProps extends PageContext {
  data: {
    allContentstackForgeNewsArticles: AllContentStackArticlesData;
    contentstackForgeNewsPage: FeaturedContentStackArticlesData;
    newsSeo: SeoData;
  };
}

const NewsPage: React.FC<NewsPageProps> = ({
  data: {
    allContentstackForgeNewsArticles: { nodes: news },
    contentstackForgeNewsPage,
  },
}) => {
  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();
  const newsUrl = `https://${siteDomain}/${language}/news/`;
  const featured = contentstackForgeNewsPage?.featured_news.reference || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return featured.length === 0 && news.length === 0 ? null : (
    <>
      <Layout>
        <Seo data={contentstackForgeNewsPage.news_seo_meta} url={newsUrl} locale={language} />
        {featured.length > 0 && <NewsHero list={featured} />}
        {news.length > 0 && <NewsArchive list={news} title={contentstackForgeNewsPage} />}
      </Layout>{" "}
    </>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsQuery($language: String, $uid: String) {
    allContentstackForgeNewsArticles(
      sort: { fields: date, order: DESC }
      filter: { publish_details: { locale: { eq: $language } }, uid: { eq: $uid } }
    ) {
      nodes {
        authors {
          title
        }
        category {
          title
          machine_name
        }
        banner {
          featured_banner_url {
            url
          }
          hero_banner_url {
            url
          }
          small_carousel_url {
            url
          }
        }
        banner_settings {
          focal_point
          banner_theme
        }
        article_type
        date
        description
        title
        id
        uid
        external_link
        url {
          url
        }
      }
    }
    contentstackForgeNewsPage(publish_details: { locale: { eq: $language } }) {
      archive_title
      news_seo_meta {
        pageTitle: page_title
        pageDescription: page_description
        siteImage: site_image {
          url
        }
        url
        siteName: site_name
      }
      featured_news {
        reference {
          banner {
            featured_banner_url {
              url
              dimension {
                height
                width
              }
            }
            hero_banner_url {
              url
            }
            small_carousel_url {
              url
            }
          }
          banner_settings {
            focal_point
            banner_theme
          }
          article_type
          date
          description
          title
          id
          uid
          external_link
          url {
            url
          }
        }
      }
    }
    ...TranslationQueryFragment
  }
`;
