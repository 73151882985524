import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentstackImg } from "@riotgames/wwpub-components";
import { localeDateString } from "../../utils/date-utils";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { truncate } from "../../utils/string-utils";
import SwiperCore, { Thumbs, Navigation, Pagination, EffectFade, Controller, Autoplay } from "swiper/core";
import SwiperClass from "swiper/types/swiper-class";
import useWindowResize from "../../hooks/use-window-resize";
import Button from "../Commons/Button";
import LeftArrow from "../../images/left_arrow_alt.svg";
import RightArrow from "../../images/right_arrow_alt.svg";
import { ArticleContentData } from "../../data/ContentStack";
import {
  ButtonWrapper,
  HeadingWrapper,
  Section,
  HeroBackground,
  Wrapper,
  Content,
  ParagraphWrapper,
  SwiperWrapper,
  ThumbItem,
  NextButton,
  PrevButton,
  Number,
  Progress,
  ThumbWrapper,
  ContentWrapper,
  ImageWrapper,
  FeaturedTitle,
  FeaturedDate,
  FeaturedParagraph,
} from "./styles";

SwiperCore.use([Thumbs, Navigation, Pagination, EffectFade, Controller, Autoplay]);

interface NewsHeroProps {
  list: ArticleContentData[];
}

const NewsHero: React.FC<NewsHeroProps> = ({ list }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(undefined);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [swiperIndex, setSwiperIndex] = useState<number | string | null>(null);
  const [isStop, setStop] = useState<boolean>(false);
  const { t } = useTranslation("news");
  const { language } = useI18next();

  // make sure that window is available before calling window.innerWidth
  useEffect(() => {
    if (window === undefined) return;
    setIsDesktop(window.innerWidth >= 1000);
  }, []);

  useWindowResize(() => setIsDesktop(window.innerWidth >= 1000));

  const second = 5;

  return (
    <SwiperWrapper>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        effect="fade"
        allowTouchMove={false}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 1000 * second,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        onInit={() => {
          setSwiperIndex(0);
        }}
        onSlideChange={(swiperCore: { realIndex: number | string }) => {
          const { realIndex } = swiperCore;
          setSwiperIndex(realIndex);
        }}
      >
        {list.map((newsItem, index) => {
          const localeDate = localeDateString(list[index].date, language);
          const newsLink = `/${language}${newsItem.url.url}`;

          const themeTriggerGrey = {
            color: ` ${newsItem.banner_settings.banner_theme === "light" ? "black" : "#A3A3A3"}`,
          };

          const themeTriggerBlack = {
            color: ` ${newsItem.banner_settings.banner_theme === "light" ? "black" : "white"}`,
          };

          return (
            <SwiperSlide key={index}>
              <Section>
                <HeroBackground
                  style={{
                    backgroundPosition: `50% ${
                      newsItem.banner_settings.focal_point ? newsItem.banner_settings.focal_point : "top"
                    }`,
                    backgroundImage: ` ${
                      newsItem.banner_settings.banner_theme === "dark"
                        ? `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 22%, rgba(0, 0, 0, 0) 100%), url("${newsItem.banner.hero_banner_url.url}")`
                        : `linear-gradient(180deg, rgba(255, 255, 255, 0.7) 22%, rgba(255, 255, 255, 0) 100%), url("${newsItem.banner.hero_banner_url.url}")`
                    }`,
                  }}
                />

                <Wrapper>
                  <section>
                    <Row justify="center">
                      <Col xs={2} sm={2} md={6} lg={11} xl={8}>
                        <ContentWrapper>
                          <Content>
                            <HeadingWrapper>
                              <FeaturedDate style={isDesktop ? themeTriggerGrey : {}}>
                                {newsItem.date && <span>{localeDate}</span>}
                              </FeaturedDate>
                              <FeaturedTitle style={isDesktop ? themeTriggerBlack : {}}>{newsItem.title}</FeaturedTitle>
                            </HeadingWrapper>
                            <ParagraphWrapper>
                              <FeaturedParagraph style={isDesktop ? themeTriggerGrey : {}}>
                                {truncate(newsItem.description, 95)}
                              </FeaturedParagraph>
                            </ParagraphWrapper>
                            <ButtonWrapper>
                              <div>
                                <Button size="large" type={"quaternary"} as="a" href={newsLink}>
                                  <p>{t("read")}</p>
                                </Button>
                              </div>
                            </ButtonWrapper>
                          </Content>
                          <ImageWrapper>
                            <ContentstackImg className="image" image={{ ...newsItem.banner.featured_banner_url }} />
                          </ImageWrapper>
                        </ContentWrapper>
                      </Col>
                    </Row>
                  </section>
                </Wrapper>
              </Section>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <ThumbWrapper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={3}
          allowTouchMove={false}
          watchSlidesVisibility={true}
        >
          <PrevButton
            onClick={() => {
              swiperRef?.slidePrev();
            }}
          >
            <LeftArrow />
          </PrevButton>
          <NextButton
            onClick={() => {
              swiperRef?.slideNext();
            }}
          >
            <RightArrow />
          </NextButton>
          {new Array(list?.length).fill({}).map((_, index) => {
            return (
              <SwiperSlide key={index}>
                <ThumbItem
                  onClick={() => {
                    setStop(true);
                    swiperRef?.autoplay.stop();
                    swiperRef?.update();
                  }}
                >
                  <Number>
                    {index > 10 ? "" : "0"}
                    {index + 1}
                  </Number>
                  <Progress active={swiperIndex === index} second={second} isStop={isStop} />
                </ThumbItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ThumbWrapper>
    </SwiperWrapper>
  );
};

export default NewsHero;
