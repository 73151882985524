import styled from "styled-components";

type HeroThemeProps = {
  isDark: boolean;
};

export const ButtonWrapper = styled.div`
  margin: 2vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    letter-spacing: 2px;
  }

  a {
    display: flex;
    align-items: center;
    margin-top: 1.25vw;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #30353f;
    margin-left: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
      font-size: 16px;
      justify-content: left;
    }
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 20px;
    }
    &:hover {
      color: #4a5160;
      svg path {
        fill: #4a5160;
      }
    }
  }

  svg {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    flex-direction: row;

    a {
      margin-top: 0.208vw;
    }

    .textLink {
      margin-left: 24px;
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    justify-content: left;
    margin-top: 0.833vw;
    margin-bottom: 0.833vw;
    a {
      margin-top: 0.208vw;
    }
  }

  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;

export const HeadingWrapper = styled.div`
  min-height: 80px;
  h1 {
    text-align: center;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin: 30px 0 0px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin: 20px 0 0px;
  }
`;

export const FeaturedDate = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #a3a3a3;
`;

export const FeaturedTitle = styled.div`
  font-size: 28px;
  font-weight: 900;
  line-height: 28px;
  color: black;
  text-transform: uppercase;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 12px 0 0px 0;
    color: white;
  }
`;

export const FeaturedParagraph = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2%;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 12px 0 0px 0;
    color: white;
  }
`;

export const Section = styled.section`
  position: relative;
  height: 100%;
  padding: 8px 20px 0px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 24px 0 0px 0;
    height: px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 3vh 0 0px 0;
    height: 715px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 3vh 0 0px 0;
  }
`;

export type Section = {
  ie_sm: string;
  ie_md: string;
  ie_lg: string;
  ie_xl: string;
};

export const HeroBackground = styled.div`
  position: absolute;
  height: 109%;
  width: 104%;
  top: -3%;
  left: -3%;
  background-size: cover;
  filter: blur(14px);
  -webkit-filter: blur(14px);
  clip-path: polygon(100% 0, 100% 27%, 0 41%, 0 0);

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 93%;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 17.5vw),
      11.75% calc(100% - 3.7vw),
      13% calc(100% - 10.5vw),
      0 calc(100% - 8.5vw)
    );
  }

  @media (min-width: 1200px) {
    height: 99%;
  }

  @media (min-width: 1400px) {
    height: 102%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0px 0;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  vertical-align: top;
  padding-top: 20px;
  margin-bottom: 18px;
  order: 2;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    order: 1;
    min-width: 250px;
    max-width: 350px;
    padding-right: 84px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  order: 1;

  .image {
    border: 3px solid black;
    height: auto;
    max-width: 100%;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      border: none;
      box-shadow: 6px 6px #111111;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      height: auto;
      width: 100%;
      max-width: 832px;
      order: 2;
    }
  }
`;

export const ParagraphWrapper = styled.div`
  margin-bottom: 3vh;
  width: 100%;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 0;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin-bottom: 10px;
  }
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0px 20px 0px;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    position: relative;
    display: block;
    margin: 0;
  }
`;

type IeImage = {
  ie_image: string;
};

// Swiper Custom Styles
export const SwiperWrapper = styled.div`
  display: block;
  position: relative;
  z-index: 5;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    height: 600px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 688px;
  }
`;

export const ThumbItem = styled.div`
  width: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 12px;
  cursor: pointer;
`;

export const NextButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 10px;
  -webkit-transform: translate(100%, 0%);
  -ms-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;
  left: auto;
`;

export const PrevButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: auto;
  -webkit-transform: translate(100%, 0%);
  -ms-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;
  left: -56px;
`;

export const Number = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

type ProgressType = {
  active: boolean;
  isStop: boolean;
  second: number | string;
  percentage?: number | string;
};

export const Progress = styled.div<ProgressType>`
  width: 46px;
  height: 6px;
  transform: skew(-20deg);
  background-color: ${(props) => props.theme.variables.secondary.base};
  margin-top: 0.208vw;
  @keyframes progress-animation {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
  &:after {
    content: "";
    height: 100%;
    background: #d8d1c8;

    ${(props) => props.active && props.isStop && "width: 100%;"}
    ${(props) =>
      props.active &&
      !props.isStop &&
      `
      -webkit-animation: progress-animation ${props.second ?? 5}s linear;
      animation: progress-animation ${props.second ?? 5}s linear;
      animation-fill-mode: forwards;
    `}
    

   
    z-index: 10;
    position: absolute;
    left: 0;
  }
`;

export const ThumbWrapper = styled.div`
  position: relative;

  .swiper-wrapper {
    align-items: center;
    justify-content: center;
  }
  .swiper-slide {
    width: auto !important;
  }
  .swiper-container {
    overflow: initial;
    position: absolute;
    left: 50%;
    bottom: 9%;
    transform: translate(-50%, 4vw);

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      transform: translate(-50%, -2vw);
    }
  }
  .swiper-container-thumbs {
    .swiper-slide {
      color: ${(props) => props.theme.variables.secondary.base};
    }
    .swiper-slide.swiper-slide-thumb-active {
      color: ${(props) => props.theme.variables.secondary.base};
    }
    .swiper-button-prev {
      cursor: pointer;
      left: 0;
      transform: translate(-100%, 0%);
      color: ${(props) => props.theme.variables.secondary.base};
      opacity: 1;
      pointer-events: initial !important;
      &::after {
        font-size: 14px;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
        left: -36px;
      }
    }
    .swiper-button-next {
      cursor: pointer;
      right: 0;
      transform: translate(100%, 0%);
      color: ${(props) => props.theme.variables.secondary.base};
      opacity: 1;
      pointer-events: initial !important;
      &::after {
        font-size: 14px;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
        right: -36px;
      }
    }
  }
`;
