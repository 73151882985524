import styled, { css } from "styled-components";

export const ArchiveTitle = styled.div`
  font-size: 28px;
  font-weight: 900;
  line-height: 28px;
  color: #676a72;
  text-transform: uppercase;
  padding: 0 20px 0;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xs}rem) {
    flex-direction: row;
    padding: 0 20px 0;
  }

  @media (min-width: 436px) {
    flex-direction: row;
    padding: 20vw 20px 0;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    padding: 70px 0px 0;
  }
`;

export const Wrapper = styled.div`
  display: block;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10vw;

  button {
    letter-spacing: 2px;
  }
  svg {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    flex-direction: row;

    .textLink {
      margin-left: 24px;
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;

export const NewsFooter = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FooterWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    height: 150px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 250px;
  }
`;
